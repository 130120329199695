.open-menu-btn {
    display: none;
    padding: 8px 8px;
    border: none;
}

.lng-button {
    display: block;
}

.main-outlet {
    flex: 1;
}

.agreements-sidebar-wrapper {
    background-color: #f5f5f5;
    height: fit-content;
    flex-basis: 300px;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.agreements-sidebar {
    
}

.agreements-sidebar-close-btn {
    display: none;
    border: none;
}

/* .agreements-sidebar-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.315);
    height: 100vh;
    width: 100vw;
} */

.agreements-sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.315);
    height: 100vh;
    width: 100vw;
}

.agreement-sidebar-lng-button {
    display: none !important;
}

button.scrollToTopBtn {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #7256ff;
    border: none;
    border-radius: 9999px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

button.scrollToTopBtn:hover {
    background-color: #5d3dff;
    color: #ffffff;
}

@media screen and (max-width: 1024px) {
    .open-menu-btn {
        display: block;
        margin-left: auto;
    }

    .lng-button {
        display: none !important;
    }

    .agreements-sidebar-wrapper {
        background-color: transparent;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        padding: 20px;
        border-radius: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in 0.1s;
    }

    .agreements-sidebar-wrapper.active {
        transform: translateX(0);
    }

    .agreements-sidebar {
        background-color: #f5f5f5;
        z-index: 101;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 88%;
        display: flex !important;
        flex-direction: column;
        padding: 20px;
        border-radius: 0;
        transform: translateX(-80%);
    }

    .agreements-sidebar.active {
        transform: translateX(0);
        transition: transform 0.3s ease-in 0.3s;
    }

    /* .agreements-sidebar-backdrop {
        z-index: 50;
        display: block;
        opacity: 0.5;
        transition: opacity 0.3s ease-in 0.2s;
    }

    .agreements-sidebar-backdrop.active {
        opacity: 1;
    } */

    .agreements-sidebar-close-btn {
        display: block;
    }

    .agreements-sidebar-content {
        flex: 1;
    }

    .agreement-sidebar-lng-button {
        display: block;
        width: 100%;
    }
}